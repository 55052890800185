import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import api from '../../api'
import {
  BirthdayDay,
  BirthdayMonth,
  BirthdayYear,
  FamiliesMetadata,
  LoginPayload,
  OnboardingStatus,
  ProfileResponse,
  RegisterEmailRequestPayload,
  ResetPasswordPayload,
  SignupPayload,
  UpdateProfileRequestPayload,
} from '../../api/types'
import { RootState } from '../store'

interface ProfileState {
  id: string | null
  email: string
  name: string
  onboardingStatus: OnboardingStatus | ''
  onboardingVersion: string
  ownerOfFaId?: number | null
  userAdminFamilies: FamiliesMetadata
  emailConfirmed: boolean
  birthdayDay: BirthdayDay | null
  birthdayMonth: BirthdayMonth | null
  birthdayYear: BirthdayYear | null
  userTimezone: string | null
  settings: {
    giftCardPricingDisplayVersion: 'A' | 'B'
    timezoneCountry: string
  }
}

// Define a type for the slice state
interface AuthState {
  profile: ProfileState
  isAuthChecked: boolean
  isAuthenticated: boolean
  isResettingPassword: boolean
  isProfileLoaded: boolean
  isCreatingPasswordResetLink: boolean
  passwordResetLinkCreationError: string
  passwordResetError: string
  loginError: string
  isLoggingIn: boolean
  signupError: string
  isSigningUp: boolean
  isRegisteringEmail: boolean
  isUpdatingProfile: boolean
  profileUpdateError: string
  isPasswordLinkCreationSuccesful: boolean
}

// Define the initial state using that type
const initialState: AuthState = {
  isAuthChecked: false,
  isAuthenticated: false,
  isResettingPassword: false,
  isProfileLoaded: false,
  isCreatingPasswordResetLink: false,
  passwordResetLinkCreationError: '',
  passwordResetError: '',
  loginError: '',
  isLoggingIn: false,
  signupError: '',
  isSigningUp: false,
  isUpdatingProfile: false,
  profileUpdateError: '',
  isRegisteringEmail: false,
  isPasswordLinkCreationSuccesful: false,
  profile: {
    id: null,
    email: '',
    name: '',
    onboardingVersion: '',
    onboardingStatus: OnboardingStatus.IN_PROGRESS,
    userAdminFamilies: [],
    emailConfirmed: false,
    birthdayDay: null,
    birthdayMonth: null,
    birthdayYear: null,
    userTimezone: null,
    settings: {
      giftCardPricingDisplayVersion: 'A',
      timezoneCountry: '',
    },
  },
}

export const getProfile = createAsyncThunk(
  'auth/getProfile',
  async function () {
    const response = await api.getProfile()
    return response
  }
)

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async function (payload: UpdateProfileRequestPayload) {
    const response = await api.updateSelfBirthday(payload)
    return response
  }
)

export const registerEmail = createAsyncThunk(
  'auth/registerEmail',
  async function (payload: RegisterEmailRequestPayload) {
    const response = await api.registerEmail(payload)
    return response
  }
)

export const login = createAsyncThunk(
  'auth/login',
  async function (payload: LoginPayload) {
    const response = await api.login(payload)
    return response
  }
)

export const signup = createAsyncThunk(
  'auth/signup',
  async function (payload: SignupPayload) {
    const response = await api.signup(payload)
    return response
  }
)

export const createPasswordResetLink = createAsyncThunk(
  'auth/createPasswordResetLink',
  async function ({ email }: { email: string }) {
    const response = await api.createPasswordResetLink({ email })
    return response
  }
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async function (payload: ResetPasswordPayload) {
    const response = await api.resetPassword(payload)
    return response
  }
)

export const completeOnboarding = createAsyncThunk(
  'auth/completeOnboarding',
  async function () {
    const response = await api.completeOnboarding()
    return response
  }
)

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: {
    [getProfile.fulfilled.type]: (
      state,
      action: PayloadAction<ProfileResponse>
    ) => {
      state.isAuthChecked = true
      state.isAuthenticated = true
      state.isProfileLoaded = true
      state.profile = action.payload
    },
    [getProfile.rejected.type]: (state, action) => {
      state.isAuthChecked = true
      state.isAuthenticated = false
    },
    [updateProfile.pending.type]: (state) => {
      state.profileUpdateError = ''
      state.isUpdatingProfile = true
    },
    [updateProfile.rejected.type]: (
      state,
      action: { error: { message: string } }
    ) => {
      state.isUpdatingProfile = false
      state.profileUpdateError = action.error.message
    },
    [updateProfile.fulfilled.type]: (
      state,
      action: PayloadAction<ProfileResponse>
    ) => {
      state.profile = { ...state.profile, ...action.payload }
      state.isUpdatingProfile = false
    },
    [registerEmail.pending.type]: (state) => {
      state.isRegisteringEmail = true
      state.signupError = ''
    },
    [registerEmail.fulfilled.type]: (
      state,
      action: PayloadAction<ProfileResponse>
    ) => {
      state.isRegisteringEmail = false
      state.profile.email = action.payload.email
    },
    [registerEmail.rejected.type]: (
      state,
      action: { error: { message: string } }
    ) => {
      state.isRegisteringEmail = false
      state.signupError = action.error.message
    },
    [login.pending.type]: (state) => {
      state.isLoggingIn = true
      state.loginError = ''
    },
    [login.fulfilled.type]: (state) => {
      state.isLoggingIn = false
      state.isAuthChecked = true
      state.isAuthenticated = true
    },
    [login.rejected.type]: (state, action: { error: { message: string } }) => {
      state.isLoggingIn = false
      state.isAuthChecked = true
      state.isAuthenticated = false
      state.loginError = action.error.message
    },
    [signup.pending.type]: (state, action) => {
      state.isSigningUp = true
      state.signupError = ''
    },
    [signup.fulfilled.type]: (state, action) => {
      state.isSigningUp = false
    },
    [signup.rejected.type]: (state, action: { error: { message: string } }) => {
      state.isSigningUp = false
      state.isAuthChecked = true
      state.isAuthenticated = false
      state.signupError = action.error.message
    },
    [resetPassword.pending.type]: (state) => {
      state.isResettingPassword = true
      state.passwordResetError = ''
    },
    [resetPassword.fulfilled.type]: (state) => {
      state.isResettingPassword = false
    },
    [resetPassword.rejected.type]: (
      state,
      action: { error: { message: string } }
    ) => {
      state.isResettingPassword = false
      state.passwordResetError = action.error.message
    },
    [createPasswordResetLink.pending.type]: (state) => {
      state.isPasswordLinkCreationSuccesful = false
      state.isCreatingPasswordResetLink = true
      state.passwordResetLinkCreationError = ''
    },
    [createPasswordResetLink.fulfilled.type]: (state) => {
      state.isCreatingPasswordResetLink = false
      state.isPasswordLinkCreationSuccesful = true
    },
    [createPasswordResetLink.rejected.type]: (
      state,
      action: { error: { message: string } }
    ) => {
      state.isCreatingPasswordResetLink = false
      state.passwordResetLinkCreationError = action.error.message
    },
    [completeOnboarding.fulfilled.type]: (
      state,
      action: PayloadAction<ProfileResponse>
    ) => {
      state.profile = { ...state.profile, ...action.payload }
    },
  },
})

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth
export const selectProfile = (state: RootState) => state.auth.profile

export const selectAuthError = createSelector([selectAuth], (authState) => {
  return (
    authState.loginError ||
    authState.signupError ||
    authState.passwordResetError ||
    authState.passwordResetLinkCreationError ||
    authState.profileUpdateError
  )
})

export const selectIsEmailRegistered = createSelector(
  [selectAuth],
  (authState) => {
    return authState.isAuthenticated && !!authState.profile.email
  }
)

export default authSlice.reducer
