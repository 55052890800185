/* eslint-disable no-console */
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { compactObject } from '../utils'
import {
  ProfileResponse,
  IFamilyAlbumCuratedPhotosResponse,
  IFamilyAlbumMediaFilesResponse,
  IFamilyAlbumPhotoSummaryResponse,
  LoginPayload,
  ResetPasswordPayload,
  SignupPayload,
  Contact,
  IPatchCelebrationPayload,
  ICelebrateFamilyAlbumMediaFilter,
  ICelebrationForRecipientResponse,
  ICreateCelebrationPayload,
  ICelebrationForSenderResponse,
  IDigitalGreetingCard,
  UpdateProfileRequestPayload,
  GetTransitionCodeResponse,
  RegisterEmailRequestPayload,
  Contacts,
  ContactPayload,
  CreateCheckoutSessionPayload,
  ICreateScheduledNotificationForRecipientOfCelebrationPayload,
  SharingMethod,
  ExperienceType,
  IGiftCard,
  EventType,
  RedeemGiftCardResponse,
} from './types'

export const parseApiValidationError = ({
  message,
  validation,
}: {
  error: string
  message: string
  validation: {
    keys: string[]
  }
}) => {
  if (validation.keys.includes('birthdayDay')) {
    return 'DAY_IS_OUT_OF_RANGE_FOR_MONTH'
  }

  if (validation.keys.includes('password') && message.includes('pattern')) {
    return 'RESET_PASSWORD_INVALID_PASSWORD'
  }

  if (validation.keys.includes('recipientEmail')) {
    return 'INVALID_EMAIL'
  }
  return message
}

export const parseApiUnautorizedtError = ({
  message,
}: {
  error: string
  message: string
}) => {
  return message
}

const parseError = (error: any) => {
  if (error.isAxiosError) {
    const axiosError = error as AxiosError

    if (axiosError.response?.status === 400) {
      return parseApiValidationError(error.response.data)
    }

    if (axiosError.response?.status === 401) {
      return parseApiUnautorizedtError(error.response.data)
    }

    if (error.response) {
      return JSON.stringify(error.response.data)
    }
  }

  return error
}

const handleError = (error: any) => {
  if (error.isAxiosError) {
    const axiosError = error as AxiosError

    if (axiosError.response?.status === 400) {
      throw Error(parseApiValidationError(error.response.data))
    }

    if (axiosError.response?.status === 401) {
      throw Error(parseApiUnautorizedtError(error.response.data))
    }

    if (error.response) {
      throw Error(JSON.stringify(error.response.data))
    }
  }

  throw Error(error)
}

const getHeaders = () => {
  return {
    'X-FAGL-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'X-FAGL-version': import.meta.env.VITE_VERSION as string,
  }
}

export class Api {
  api: AxiosInstance
  baseURL: string = ''

  constructor({ baseURL }: { baseURL: string }) {
    this.baseURL = baseURL || `http://localhost:8000`

    this.api = Axios.create({
      withCredentials: true,
      baseURL: this.baseURL,
      headers: getHeaders(),
    })
  }

  async getProfile() {
    try {
      const { data } = await this.api.get<any, AxiosResponse<ProfileResponse>>(
        '/v1/profile/',
        {
          headers: getHeaders(),
        }
      )

      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  async getFamilyAlbumPhotosSummary() {
    const { data } = (await this.api.get('/v1/family-album-photos', {
      headers: getHeaders(),
    })) as AxiosResponse<IFamilyAlbumPhotoSummaryResponse>
    return data
  }

  async getFamilyAlbumCuratedPhotos() {
    const { data } = (await this.api.get('/v1/family-album-curated-photos', {
      headers: getHeaders(),
    })) as AxiosResponse<IFamilyAlbumCuratedPhotosResponse>
    return data
  }

  async getFamilyAlbumCuratedPhotosForUserId(userId: string) {
    const { data } = (await this.api.get(
      `/v1/family-album-curated-photos/${userId}`,
      {
        headers: getHeaders(),
      }
    )) as AxiosResponse<IFamilyAlbumCuratedPhotosResponse>
    return data
  }

  async getFamilyAlbumPhotosForFamilyId(
    familyAlbumFamilyId: number,
    query: {
      filter?: ICelebrateFamilyAlbumMediaFilter | null
      cursor?: string
    }
  ) {
    const params = {
      filter: query.filter,
    }

    if (query.cursor) {
      Object.assign(params, {
        cursor: query.cursor,
      })
    }

    const { data } = (await this.api.get(
      `/v1/family-album-photos/${familyAlbumFamilyId}`,
      {
        params,
        headers: getHeaders(),
      }
    )) as AxiosResponse<IFamilyAlbumMediaFilesResponse>
    return data
  }

  async getFaPhotos(faPhotos: { url: string; uuid: string }[]) {
    const { data } = (await this.api.post(
      '/v1/family-album-photos/covert-to-base-64',
      {
        faPhotos,
      },
      {
        headers: getHeaders(),
      }
    )) as AxiosResponse<{ url: string; uuid: string }[]>
    return data
  }

  // async updateProfile(payload: UpdateProfileRequestPayload) {
  //   const headers = getHeaders()
  //   try {
  //     const { data } = (await this.api.patch('/v1/profile/', payload, {
  //       headers,
  //     })) as AxiosResponse<ProfileResponse>

  //     return data
  //   } catch (err: any) {
  //     handleError(err)
  //   }
  // }

  async updateSelfBirthday(payload: UpdateProfileRequestPayload) {
    const headers = getHeaders()
    try {
      const { data } = (await this.api.patch(
        '/v1/profile/self-birthday',
        payload,
        {
          headers,
        }
      )) as AxiosResponse<ProfileResponse>

      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  async muteSelfBirthdayCollection() {
    const headers = getHeaders()
    try {
      const { data } = (await this.api.patch(
        '/v1/profile/mute-self-birthday-collection',
        {},
        {
          headers,
        }
      )) as AxiosResponse<ProfileResponse>

      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  async registerEmail(payload: RegisterEmailRequestPayload) {
    try {
      const { data } = (await this.api.post(
        '/v1/profile/register-email',
        payload,
        {
          headers: getHeaders(),
        }
      )) as AxiosResponse<ProfileResponse>

      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  async importContacts() {
    await this.api.post('/v1/profile/import-contacts', {
      headers: getHeaders(),
    })
  }

  async getTransitionCode(): Promise<GetTransitionCodeResponse> {
    try {
      const { data } = (await this.api.get(
        '/v1/auth/transit/'
      )) as AxiosResponse<GetTransitionCodeResponse>
      return data
    } catch (err: any) {
      handleError(err)
      return { transitionCode: '' }
    }
  }

  async loginWithTransitionCode(transitionCode: string) {
    try {
      await this.api.post('/v1/auth/code', {
        transitionCode,
      })
    } catch (err: any) {
      handleError(err)
    }
  }

  async login(payload: LoginPayload) {
    try {
      await this.api.post('/v1/auth/login', payload)
    } catch (err: any) {
      handleError(err)
    }
  }

  async signup(payload: SignupPayload) {
    if (!payload.uuid) {
      delete payload.uuid
    }

    try {
      throw new Error('Not implemented')
    } catch (err: any) {
      handleError(err)
    }
  }

  async requestEmailConfirmationLink(email: string) {
    const { data } = await this.api.post(
      '/v1/auth/request-email-confirmation-link',
      { email }
    )
    return data
  }

  async createPasswordResetLink({ email }: { email: string }) {
    const response = await this.api.post(
      '/v1/auth/create-reset-password-link',
      {
        email,
      }
    )
    return response.data
  }

  async resetPassword(payload: ResetPasswordPayload) {
    try {
      const response = await this.api.post('/v1/auth/reset-password', payload)
      return response
    } catch (error: any) {
      handleError(error)
    }
  }

  async fetchContacts() {
    const response = await this.api.get<any, AxiosResponse<Contacts>>(
      '/v1/contacts',
      {
        headers: getHeaders(),
      }
    )

    return response.data
  }

  async updateContactWithId(
    contactId: number,
    payload: Partial<ContactPayload>
  ) {
    if (!payload.birthdayYear) {
      payload.birthdayYear = null
    }
    try {
      const response = (await this.api.patch(
        `/v1/contacts/${contactId}`,
        payload,
        {
          headers: getHeaders(),
        }
      )) as AxiosResponse<Contact>

      return response.data
    } catch (err) {
      handleError(err)
    }
  }

  async updateContactNameWithId(contactId: number, name: string) {
    try {
      const response = (await this.api.patch(
        `/v1/contacts/${contactId}`,
        { name },
        {
          headers: getHeaders(),
        }
      )) as AxiosResponse<Contact>

      return response.data
    } catch (err) {
      handleError(err)
    }
  }

  async createContact(payload: ContactPayload) {
    if (!payload.birthdayYear) {
      payload.birthdayYear = null
    }

    try {
      const response = (await this.api.post('/v1/contacts/', payload, {
        headers: getHeaders(),
      })) as AxiosResponse<Contact>

      return response.data
    } catch (err) {
      handleError(err)
    }
  }

  async deleteContactWithId(contactId: number) {
    const response = (await this.api.delete(`/v1/contacts/${contactId}`, {
      headers: getHeaders(),
    })) as AxiosResponse<Contact>

    return response.data
  }

  async completeOnboarding() {
    const { data } = (await this.api.post(
      '/v1/profile/onboarding-status/complete',
      {},
      {
        headers: getHeaders(),
      }
    )) as AxiosResponse<ProfileResponse>

    return data
  }

  async createCelebrationVideoMessageUploadLink({
    fileName,
    fileType,
  }: {
    fileName: string
    fileType: string
  }) {
    const { data } = (await this.api.post(
      '/v1/media/celebration-video-message',
      {
        fileName,
        fileType,
      }
    )) as AxiosResponse<{
      signedRequest: string
      url: string
    }>

    return data
  }

  async createCelebrationDgcPhotoUploadLink({
    fileName,
    fileType,
  }: {
    fileName: string
    fileType: string
  }) {
    const { data } = (await this.api.post('/v1/media/celebration-dgc-photo', {
      fileName,
      fileType,
    })) as AxiosResponse<{
      signedRequest: string
      url: string
    }>

    return data
  }

  async createGenAIZippedPhotosUploadLink({
    fileName,
    fileType,
  }: {
    fileName: string
    fileType: string
  }) {
    const { data } = (await this.api.post('/v1/media/celebration-dgc-photo', {
      fileName,
      fileType,
    })) as AxiosResponse<{
      signedRequest: string
      url: string
    }>

    return data
  }

  async createCheckoutSession(payload: CreateCheckoutSessionPayload) {
    const { data } = await this.api.post(
      '/v1/payments/checkout-session',
      payload,
      {
        headers: getHeaders(),
      }
    )

    return data
  }

  async createCheckoutSessionForFaGiftCardGiftCard(payload: {
    currency: string
    amount: number
    message: string
    senderName: string
    recipientName: string
    recipientContactId: number
  }) {
    const { data } = await this.api.post<
      any,
      AxiosResponse<{
        sessionUrl: string
        checkoutSessionId: string
        celebrationId: string
      }>
    >('/v1/payments/checkout-session/fa-gift-card', payload, {
      headers: getHeaders(),
    })

    return data
  }

  async createCelebration(payload: ICreateCelebrationPayload) {
    try {
      const { data } = await this.api.post<
        any,
        AxiosResponse<ICelebrationForSenderResponse>
      >('/v2/celebrations', payload, {
        headers: getHeaders(),
      })
      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  async createScheduledNotificationForRecipientOfCelebrationPayload({
    celebrationId,
    payload,
  }: {
    celebrationId: string
    payload: ICreateScheduledNotificationForRecipientOfCelebrationPayload
  }) {
    try {
      const { data } = await this.api.post<
        any,
        AxiosResponse<ICelebrationForSenderResponse>
      >(`/v2/celebrations/${celebrationId}/schedule`, payload, {
        headers: getHeaders(),
      })

      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  async createCelebrationCopy(celebrationId: string) {
    try {
      const { data } = await this.api.post<
        any,
        AxiosResponse<{ id: string; digitalGreetingCardId: number | null }>
      >(
        `/v2/celebrations/${celebrationId}/copy`,
        {},
        {
          headers: getHeaders(),
        }
      )

      return data
    } catch (err: any) {
      throw new Error(parseError(err))
    }
  }

  async patchCelebration({
    celebrationId,
    payload,
  }: {
    celebrationId: string
    payload: IPatchCelebrationPayload
  }) {
    const { data } = (await this.api.patch(
      `/v2/celebrations/${celebrationId}`,
      payload,
      {
        headers: getHeaders(),
      }
    )) as AxiosResponse<ICelebrationForSenderResponse>

    return data
  }

  async getCelebrationWithId(celebrationId: string) {
    const { data } = await this.api.get<
      any,
      AxiosResponse<ICelebrationForSenderResponse>
    >(`/v1/celebrations/${celebrationId}/`, {
      headers: getHeaders(),
    })

    return data
  }

  async getCelebrationWithIdForRecipient(celebrationId: string) {
    const { data } = (await this.api.get(
      `/v1/celebrations/${celebrationId}/view`,
      {
        headers: getHeaders(),
      }
    )) as AxiosResponse<ICelebrationForRecipientResponse>

    return data
  }

  async markCelebrationAsViewed(celebrationId: string) {
    try {
      await this.api.patch(`/v2/celebrations/${celebrationId}/viewed`, {
        headers: getHeaders(),
      })
    } catch (err) {
      console.error(err)
    }
  }

  async getGiftCardsV2(query: {
    cursor: string
    q: string
    country: string
    pageSize: number
    customOccasion?: string
  }) {
    const { data } = (await this.api.get('/v2/gift-cards', {
      headers: getHeaders(),
      params: compactObject(query),
    })) as AxiosResponse<{
      giftCards: IGiftCard[]
      newCursor: string
      total: number
    }>

    return data
  }

  async getGiftCard(code: string) {
    const { data } = await this.api.get<any, AxiosResponse<IGiftCard>>(
      `/v2/gift-cards/${code}`,
      {
        headers: getHeaders(),
      }
    )

    return data
  }

  async getDigitalGreetingCards(eventType: EventType) {
    const { data } = (await this.api.get('/v1/digital-greeting-cards', {
      headers: getHeaders(),
      params: {
        type: eventType,
      },
    })) as AxiosResponse<IDigitalGreetingCard[]>

    return data
  }

  async recordCelebrationCreationStarted(
    experienceType: ExperienceType,
    recipientContactId: number
  ) {
    await this.api.post(
      `/v1/celebrations/dripCampaign`,
      {
        experienceType,
        recipientContactId,
      },
      {
        headers: getHeaders(),
      }
    )
  }

  recordUserAction = async (action: string, metadata?: object) => {
    const payload = {
      action,
    }

    if (metadata) {
      Object.assign(payload, { metadata })
    }

    try {
      const { data } = await this.api.post(`/v1/user-actions/`, payload, {
        headers: getHeaders(),
      })

      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  sendThankYouMessage = async (celebrationId: string, message: string) => {
    const response = await this.api.post<
      any,
      AxiosResponse<{
        success: boolean
        error: string
      }>
    >(
      `/v1/celebrations/${celebrationId}/thank-you-message`,
      { message },
      {
        headers: getHeaders(),
      }
    )

    return response.data
  }

  redeemGiftCard = async (celebrationId: string) => {
    try {
      const { data } = (await this.api.post(
        `/v2/celebrations/${celebrationId}/redeem-gift-card`,
        {},
        {
          headers: getHeaders(),
        }
      )) as AxiosResponse<RedeemGiftCardResponse>

      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  setSharingMethodForCelebration = async (
    celebrationId: string,
    sharingMethod: SharingMethod
  ) => {
    try {
      const { data } = await this.api.patch<
        any,
        AxiosResponse<ICelebrationForSenderResponse>
      >(
        `/v2/celebrations/${celebrationId}/sharing-method`,
        { sharingMethod },
        {
          headers: getHeaders(),
        }
      )

      return data
    } catch (err: any) {
      handleError(err)
    }
  }

  getFamilyAlbumGiftCardRedemptionDeeplink = async (celebrationId: string) => {
    const { data } = await this.api.get<
      any,
      AxiosResponse<{
        deepLink: string
      }>
    >(
      `/v2/celebrations/${celebrationId}/family-album-gift-card-order/deep-link`
    )

    return data
  }
}

const api = new Api({
  baseURL: import.meta.env.VITE_API_BASE_URL as string,
})

export default api
