import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { keyBy, orderBy } from 'lodash'
import api from '../../api'
import { EventType, IDigitalGreetingCard } from '../../api/types'
import { ErrorAction } from '../hooks'
import { RootState } from '../store'

interface DigitalGreetingCardsState {
  areGreetingCardsLoaded: boolean
  digitalGreetingCardsById: Record<string, IDigitalGreetingCard>
  error: string
}

const initialState: DigitalGreetingCardsState = {
  areGreetingCardsLoaded: false,
  digitalGreetingCardsById: {},
  error: '',
}

export const getDigitalGreetingCards = createAsyncThunk(
  'digitalGreetingCards/getDigitalGreetingCards',
  async function (eventType: EventType) {
    const response = await api.getDigitalGreetingCards(eventType)
    return response
  }
)

export const digitalGreetingCardsSlice = createSlice({
  name: 'digitalGreetingCards',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: {
    [getDigitalGreetingCards.pending.type]: (state) => {
      state.areGreetingCardsLoaded = false
    },
    [getDigitalGreetingCards.fulfilled.type]: (
      state,
      { payload: digitalGreetingCards }: PayloadAction<IDigitalGreetingCard[]>
    ) => {
      state.digitalGreetingCardsById = keyBy(digitalGreetingCards, 'id')
      state.areGreetingCardsLoaded = true
    },
    [getDigitalGreetingCards.rejected.type]: (state, action: ErrorAction) => {
      state.areGreetingCardsLoaded = true
      state.error = action.error.message
    },
  },
})

const selectState = ({ digitalGreetingCards }: RootState) =>
  digitalGreetingCards

export const selectDgcState = createSelector([selectState], (state) => ({
  ...state,
  digitalGreetingCards: orderBy(
    state.digitalGreetingCardsById,
    ['type', 'order'],
    ['asc', 'asc']
  ).map((dgc) => ({
    ...dgc,
    thumbUrl: dgc.coverImageFileName.replace('.jpg', '_thumb.jpg'),
  })),
}))

export default digitalGreetingCardsSlice.reducer
