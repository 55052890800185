import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { EventType, InteractiveOpen } from '../../api/types'
import { RootState } from '../store'

export type VideoMessageBackgroundMusic = {
  url: string
  title: string
  price: number
  eventType: EventType
  showPrice: boolean
  isDefault?: boolean
}

export type VideoMessageOverlay = {
  url: string
  title: string
  price: number
  type: 'IMAGE'
  eventType: EventType
  interactiveOpen: InteractiveOpen
  defaultBgMusicUrl: string
  bgColor?: string
}

const BG_MUSIC: VideoMessageBackgroundMusic[] = [
  {
    title: 'Heartwarming Piano',
    url: '/music-video/beautiful-moments.mp3',
    price: 99,
    eventType: EventType.BIRTHDAY,
    showPrice: true,
    isDefault: true,
  },
  {
    title: 'Warm Bells',
    url: '/music-video/air-time.mp3',
    price: 99,
    eventType: EventType.BIRTHDAY,
    showPrice: true,
  },
  {
    title: 'Happy Guitar',
    url: '/music-video/happy-acoustic.mp3',
    price: 99,
    eventType: EventType.BIRTHDAY,
    showPrice: true,
  },
  {
    title: 'Gentle Piano',
    url: '/music-video/visions-of-the-future.mp3',
    price: 99,
    eventType: EventType.BIRTHDAY,
    showPrice: true,
  },
  {
    title: 'Warm Acoustic',
    url: '/music-video/soulmate.mp3',
    price: 99,
    eventType: EventType.BIRTHDAY,
    showPrice: true,
  },
  {
    title: 'Peaceful Acoustic',
    url: '/music-video/the-statue-garden.mp3',
    price: 99,
    eventType: EventType.BIRTHDAY,
    showPrice: true,
  },
  {
    title: 'Jingle Bells (Jolly)',
    url: '/music-video/jingle-bells.mp3',
    price: 0,
    eventType: EventType.HOLIDAY,
    showPrice: false,
  },
  {
    title: 'Jingle Bells (Jazzy)',
    url: '/music-video/jingle-bells-whistle.mp3',
    price: 0,
    eventType: EventType.HOLIDAY,
    showPrice: false,
  },
  {
    title: 'Serene Hanukkah',
    url: '/music-video/maoz-tzur.mp3',
    price: 0,
    eventType: EventType.HOLIDAY,
    showPrice: false,
  },
  {
    title: 'We Wish You a Merry Christmas',
    url: '/music-video/we-wish-you-a-merry-xmas.mp3',
    price: 0,
    eventType: EventType.HOLIDAY,
    showPrice: false,
  },
  {
    title: 'Wintry Carol',
    url: '/music-video/deck-the-halls.mp3',
    price: 0,
    eventType: EventType.HOLIDAY,
    showPrice: false,
  },
  {
    title: 'New Years Favorite',
    url: '/music-video/auld-lang-syne.mp3',
    price: 0,
    eventType: EventType.HOLIDAY,
    showPrice: false,
    isDefault: true,
  },
  {
    title: 'New Years Favorite',
    url: '/music-video/auld-lang-syne.mp3?eventType=NYE',
    price: 0,
    eventType: EventType.NYE,
    showPrice: false,
    isDefault: true,
  },
  {
    title: 'Heartwarming Piano',
    url: '/music-video/beautiful-moments.mp3?eventType=NYE',
    price: 0,
    eventType: EventType.NYE,
    showPrice: false,
  },
  {
    title: 'Happy Guitar',
    url: '/music-video/happy-acoustic.mp3?eventType=NYE',
    price: 0,
    eventType: EventType.NYE,
    showPrice: false,
  },
  {
    title: 'Warm Acoustic',
    url: '/music-video/soulmate.mp3?eventType=NYE',
    price: 0,
    eventType: EventType.NYE,
    showPrice: false,
  },
]

const BG_MUSIC_BY_URL = keyBy(BG_MUSIC, 'url')

const OVERLAYS: VideoMessageOverlay[] = [
  {
    title: 'Merry Christmas',
    type: 'IMAGE',
    url: '/video-message-overlays/christmas.png',
    price: 0,
    eventType: EventType.HOLIDAY,
    interactiveOpen: InteractiveOpen.HOLIDAY_XMAS,
    defaultBgMusicUrl: '/music-video/jingle-bells.mp3',
  },
  {
    title: 'Happy Holidays',
    url: '/video-message-overlays/holidays.png',
    type: 'IMAGE',
    price: 0,
    eventType: EventType.HOLIDAY,
    interactiveOpen: InteractiveOpen.HOLIDAY_DEFAULT,
    defaultBgMusicUrl: '/music-video/auld-lang-syne.mp3',
  },
  {
    title: 'Happy New Year',
    url: '/video-message-overlays/nye2.png',
    type: 'IMAGE',
    price: 0,
    eventType: EventType.NYE,
    interactiveOpen: InteractiveOpen.NYE,
    defaultBgMusicUrl: '/music-video/auld-lang-syne.mp3?eventType=NYE',
  },
  {
    title: '2023 - Happy New Year',
    url: '/video-message-overlays/nye1.png',
    type: 'IMAGE',
    price: 0,
    eventType: EventType.NYE,
    interactiveOpen: InteractiveOpen.NYE,
    defaultBgMusicUrl: '/music-video/auld-lang-syne.mp3?eventType=NYE',
    bgColor:
      'linear-gradient(180.18deg, rgba(7, 84, 115, 0) 21.87%, rgba(7, 84, 115, 0.5) 55.27%, #075473 105.94%)',
  },
  {
    title: 'Happy Holidays',
    url: '/video-message-overlays/holidays.png?eventType=NYE',
    type: 'IMAGE',
    price: 0,
    eventType: EventType.NYE,
    interactiveOpen: InteractiveOpen.NYE,
    defaultBgMusicUrl: '/music-video/auld-lang-syne.mp3?eventType=NYE',
  },

  {
    title: 'Party Pup',
    url: '/video-message-overlays/party_pup.png',
    type: 'IMAGE',
    price: 0,
    eventType: EventType.BIRTHDAY,
    interactiveOpen: InteractiveOpen.BIRTHDAY_DEFAULT,
    defaultBgMusicUrl: '/music-video/beautiful-moments.mp3',
  },
  {
    title: 'Birthday Balloons',
    url: '/video-message-overlays/birthday_balloons.png',
    type: 'IMAGE',
    price: 0,
    eventType: EventType.BIRTHDAY,
    interactiveOpen: InteractiveOpen.BIRTHDAY_DEFAULT,
    defaultBgMusicUrl: '/music-video/beautiful-moments.mp3',
  },
  {
    title: 'Animal March',
    url: '/video-message-overlays/animal_march.png',
    type: 'IMAGE',
    price: 0,
    eventType: EventType.BIRTHDAY,
    interactiveOpen: InteractiveOpen.BIRTHDAY_DEFAULT,
    defaultBgMusicUrl: '/music-video/beautiful-moments.mp3',
  },
  {
    title: 'Happy Rainbow',
    url: '/video-message-overlays/happy_rainbow.png',
    type: 'IMAGE',
    price: 0,
    eventType: EventType.BIRTHDAY,
    interactiveOpen: InteractiveOpen.BIRTHDAY_DEFAULT,
    defaultBgMusicUrl: '/music-video/beautiful-moments.mp3',
  },
]

const OVERLAYS_BY_URL = keyBy(OVERLAYS, 'url')

export type InteractiveOpenMetadata = {
  firstFrameAnimationDataUrl: string
  secondFrameAnimationDataUrl: string
  firstFrameTimeout: number // 120
  secondFrameTimeout: number // 80
  loopingSoundUrl: string
  tapSoundUrl: string
  animationOpenLoopSprite: string
  animationOpenSprites: Record<string, [number, number, boolean]>
}

export const INTERACTIVE_OPENS: Record<string, InteractiveOpenMetadata> = {
  BIRTHDAY_DEFAULT: {
    firstFrameAnimationDataUrl: '/interactive-opens/BIRTHDAY_DEFAULT_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/BIRTHDAY_DEFAULT_2.json',
    firstFrameTimeout: 120,
    secondFrameTimeout: 80,
    loopingSoundUrl: '/animation_template_opening.mp3',
    tapSoundUrl: '/animation_template_transition.mp3',
    animationOpenSprites: {},
    animationOpenLoopSprite: '',
  },
  HOLIDAY_DEFAULT: {
    firstFrameAnimationDataUrl: '/interactive-opens/HOLIDAY_DEFAULT_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/HOLIDAY_DEFAULT_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 240,
    loopingSoundUrl: '/animation_template_opening_holiday.mp3',
    tapSoundUrl: '/animation_template_transition_holiday.mp3',
    animationOpenSprites: {},
    animationOpenLoopSprite: '',
  },
  HOLIDAY_XMAS: {
    firstFrameAnimationDataUrl: '/interactive-opens/HOLIDAY_XMAS_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/HOLIDAY_XMAS_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 240,
    loopingSoundUrl: '/animation_template_opening_xmas.mp3',
    tapSoundUrl: '/animation_template_transition_xmas.mp3',
    animationOpenSprites: {},
    animationOpenLoopSprite: '',
  },
  NYE: {
    firstFrameAnimationDataUrl: '/interactive-opens/NEW_YEAR_2022_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/NEW_YEAR_2022_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 305,
    loopingSoundUrl: '/animation_template_opening_nye.mp3',
    tapSoundUrl: '/animation_template_transition_nye.mp3',
    animationOpenSprites: {},
    animationOpenLoopSprite: '',
  },
  MOTHERS_DAY: {
    firstFrameAnimationDataUrl: '/interactive-opens/MOTHERS_DAY_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/MOTHERS_DAY_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 305,
    loopingSoundUrl: '/animation_template_opening_mothers_day.mp3',
    tapSoundUrl: '/animation_template_transition_mothers_day.mp3',
    animationOpenSprites: {
      first: [0, 6950, true],
    },
    animationOpenLoopSprite: 'first',
  },
  FATHERS_DAY: {
    firstFrameAnimationDataUrl: '/interactive-opens/FATHERS_DAY_1.json',
    secondFrameAnimationDataUrl: '/interactive-opens/FATHERS_DAY_2.json',
    firstFrameTimeout: 0,
    secondFrameTimeout: 320,
    loopingSoundUrl: '/animation_template_opening_fathers_day.mp3',
    tapSoundUrl: '/animation_template_transition_fathers_day.mp3',
    animationOpenSprites: {
      first: [0, 11000, true],
    },
    animationOpenLoopSprite: 'first',
  },
}

interface CelebrationAssetsState {
  videoMessageBgMusicByUrl: Record<string, VideoMessageBackgroundMusic>
  videoMessageOverlaysByUrl: Record<string, VideoMessageOverlay>
  interactiveOpens: Record<string, InteractiveOpenMetadata>
  defaultOverlayUrlByEventType: Record<EventType, string>
}

const initialState: CelebrationAssetsState = {
  videoMessageBgMusicByUrl: BG_MUSIC_BY_URL,
  videoMessageOverlaysByUrl: OVERLAYS_BY_URL,
  interactiveOpens: INTERACTIVE_OPENS,
  defaultOverlayUrlByEventType: {
    [EventType.BIRTHDAY]: '',
    [EventType.HOLIDAY]: '/video-message-overlays/holidays.png',
    [EventType.NYE]: '/video-message-overlays/nye2.png',
    [EventType.THANK_YOU]: '',
    [EventType.MOTHERS_DAY]: '',
    [EventType.FATHERS_DAY]: '',
    [EventType.BABY_MILESTONE_1]: '',
    [EventType.BABY_MILESTONE_3]: '',
    [EventType.BABY_MILESTONE_6]: '',
    [EventType.BABY_MILESTONE_9]: '',
    [EventType.GRANDPARENTS_DAY]: '',
    [EventType.NEW_BABY]: '',
    [EventType.THANKSGIVING]: '',
  },
}

export const celebrtionAssetsSlice = createSlice({
  name: 'celebrationAssets',
  initialState,
  reducers: {},
  extraReducers: {},
})

export const selectCelebrationAssetsState = ({
  celebrationAssets,
}: RootState) => celebrationAssets

export default celebrtionAssetsSlice.reducer
